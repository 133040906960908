<template>
  <div>
    <div v-if="showBanner" class="page-overlay"></div>
    <div v-if="showBanner" class="cookie-banner">
      <p style="color: #003366"><b>{{this.localizedContent('status')}}:</b> {{consentMessage}}</p>
      <div>
        <p v-if="defaultLanguage === 'de'">
          Damit wir Cookies zur Nutzung unseres Webanalyse-Tools <strong> Matomo Analytics </strong>verwenden dürfen, benötigen wir Ihre Einwilligung.
          Es steht Ihnen frei, die Webanalyse abzulehnen oder zu akzeptieren und Ihre diesbezügliche Entscheidung jederzeit zu ändern.
          Wenn Sie die Webanalyse ablehnen, können Sie den Konfliktnavigator dennoch nutzen. Da der Konfliktnavigator Ihnen zweierlei zur Verfügung steht
          (Unterstützung bei der Erstellung einer Vertragsklausel oder bei der Suche nach dem für Sie passenden Streitbeilegungsverfahren),
          erteilen Sie Ihre Einwilligung oder Ablehnung zur Webanalyse einheitlich für beide Nutzungsmöglichkeiten.
        </p>
        <p v-if="defaultLanguage === 'en'">
          In order to use cookies for our web analytics tool <strong> Matomo Analytics </strong>, we need your consent.
          You are free to reject or accept web analytics and change your decision at any time.
          If you reject web analytics, you can still use the Conflict Navigator. Since the Conflict Navigator offers you two options
          (assistance in creating a contract clause or in finding the dispute resolution procedure that suits you best),
          you give your consent or rejection to web analytics uniformly for both usage options.
        </p>

        <p v-if="defaultLanguage === 'de'">
          Sofern Sie uns Ihre Einwilligung erteilt haben, verwenden wir Cookies zur Nutzung unseres Webanalyse-Tools <strong>
          Matomo Analytics</strong>. Durch einen Klick auf den Button <strong>„Webanalyse akzeptieren“</strong> erteilen
          Sie uns Ihre Einwilligung dahingehend, dass wir zu Analysezwecken Cookies setzen und die sich ergebenden Daten
          verarbeiten dürfen. Die Rechtsgrundlage ist § 25 Abs. 1 S. 1 TDDDG. Ihr Nutzungsverhalten wird anonymisiert ausgewertet.
        </p>
        <p v-if="defaultLanguage === 'en'">
          If you have given us your consent, we use cookies for our web analytics tool <strong>
          Matomo Analytics</strong>. By clicking on the button <strong>“Accept Web Analytics”</strong>, you give
          us your consent to set cookies for analysis purposes and process the resulting data.
          The legal basis is § 25 Abs. 1 S. 1 TDDDG. Your usage behavior is evaluated anonymously.
        </p>
        <p>
    <span v-if="defaultLanguage === 'de'">
      Sie können Ihre Einwilligung zur Webanalyse oder Ablehnung der Webanalyse jederzeit ändern, indem Sie auf der Seite links oben das Schlosssymbol
    </span>
          <span v-if="defaultLanguage === 'en'">
      You can change your consent ore rejection to web analytics at any time by clicking on the lock icon
    </span>
          <img  src="../../public/images/lock-small.svg"/>
          <span v-if="defaultLanguage === 'de'">
      anklicken und dann <strong>"Webanalyse
        ablehnen"</strong> oder <strong>"Webanalyse akzeptieren"</strong> auswählen.
        Ebenso können Sie bei Klick auf das Schlosssymbol einsehen, ob Sie aktuell die Webanalyse akzeptiert oder abgelehnt haben (<strong>"Aktueller Status"</strong>).

    </span>
          <span v-if="defaultLanguage === 'en'">
      at the top left of the page and then selecting <strong>"Reject Web Analytics"</strong> or <strong>"Accept Web Analytics"</strong>.
          You can also see whether you currently accept or reject web analytics by clicking on the lock icon (<strong>"Current Status"</strong>).
    </span>
        </p>
      </div>
      <!-- Expandable section -->
      <div v-if="isTextExpanded">
        <!-- The content to show when expanded -->
        <p ><b>{{this.localizedContent('matomoCookiesHeadline')}}</b><br></p>
        <ul >
          <li class="cookielist">
            <b>_pk_id</b>
            <br> {{this.localizedContent('matomoCookieType')}}: {{this.localizedContent('matomoCookieTypeTrackingCookie')}}
            <br> {{this.localizedContent('matomoCookieDescription')}}: {{this.localizedContent('matomoCookieDescriptionPkId')}}
            <br> {{this.localizedContent('matomoCookiePurpose')}}: {{this.localizedContent('matomoCookiePurposeTracking')}}
            <br> {{this.localizedContent('matomoCookieDefaultExpiry')}}: {{this.localizedContent('matomoCookieDefaultExpiry12Months')}}
          </li>
          <li class="cookielist">
            <b>_pk_ses, _pk_cvar, _pk_hsr</b>
            <br> {{this.localizedContent('matomoCookieType')}}: {{this.localizedContent('matomoCookieTypeTrackingCookie')}}
            <br> {{this.localizedContent('matomoCookieDescription')}}: {{this.localizedContent('matomoCookieDescriptionPkSes')}}
            <br> {{this.localizedContent('matomoCookiePurpose')}}: {{this.localizedContent('matomoCookiePurposeTracking')}}
            <br> {{this.localizedContent('matomoCookieDefaultExpiry')}}: {{this.localizedContent('matomoCookieDefaultExpiry30Minutes')}}
          </li>
          <li class="cookielist">
            <b>_pk_ref</b>
            <br> {{this.localizedContent('matomoCookieType')}}: {{this.localizedContent('matomoCookieTypeTrackingCookie')}}
            <br> {{this.localizedContent('matomoCookieDescription')}}: {{this.localizedContent('matomoCookieDescriptionPkRef')}}
            <br> {{this.localizedContent('matomoCookiePurpose')}}: {{this.localizedContent('matomoCookiePurposeTracking')}}
            <br> {{this.localizedContent('matomoCookieDefaultExpiry')}}: {{this.localizedContent('matomoCookieDefaultExpiry6Months')}}
          </li>
          <li class="cookielist">
            <b>mtm_consent</b>
            <br> {{this.localizedContent('matomoCookieType')}}: {{this.localizedContent('matomoCookieTypeFunctionalCookie')}}
            <br> {{this.localizedContent('matomoCookieDescription')}}: {{this.localizedContent('matomoCookieDescriptionMtmConsent')}}
            <br> {{this.localizedContent('matomoCookiePurpose')}}: {{this.localizedContent('matomoCookiePurposeFunctional')}}
            <br> {{this.localizedContent('matomoCookieDefaultExpiry')}}: {{this.localizedContent('matomoCookieDefaultExpiry12Months')}}
          </li>
          <li class="cookielist">
            <b>mtm_consent_removed</b>
            <br> {{this.localizedContent('matomoCookieType')}}: {{this.localizedContent('matomoCookieTypeFunctionalCookie')}}
            <br> {{this.localizedContent('matomoCookieDescription')}}: {{this.localizedContent('matomoCookieDescriptionMtmConsentRemoved')}}
            <br> {{this.localizedContent('matomoCookiePurpose')}}: {{this.localizedContent('matomoCookiePurposeFunctional')}}
            <br> {{this.localizedContent('matomoCookieDefaultExpiry')}}: {{this.localizedContent('matomoCookieDefaultExpiry12Months')}}
          </li>
        </ul>
        <!-- end cookie content -->

      </div>

      <div class="button-container">
        <div>
          <a href="javascript:void(0)" @click="toggleText" class="custom-link">{{
              isTextExpanded ? this.localizedContent('hideCookiesInformation') : this.localizedContent('showCookiesInformation')
            }}</a>
        </div>
        <div><a :href=this.impressumLink target="_blank" class="custom-link"
               >{{this.localizedContent('legalNotice')}}</a>
        </div>
        <div><a :href=this.datenschutzLink target="_blank" class="custom-link" style=" padding: 0.2em 0;">
          {{this.localizedContent('dataProtection')}}
        </a></div>

        <button @click="acceptCookies">{{this.localizedContent('btnAcceptWebTracking')}}</button>
        <button @click="rejectCookies">{{this.localizedContent('btnRejectWebTracking')}}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
/*    localized: {
      type: Function,
      required: true,
    },*/
    defaultLanguage: {
      type: String,
      required: true,
    },
/*    appText: {
      type: Object,
      required: true,
    },*/
  },
  data() {
    return {
      lockIcon: require('../assets/lock-small.svg'), // Adjust the path relative to this file
      showBanner: false,
      isTextExpanded: false,
      matomoConsent: null,
      datenschutzLink: "https://schiedsgerichtshof.de/wp-content/uploads/2024/11/Datenschutzerklaerung_Website_SGH.pdf",
      impressumLink: "https://schiedsgerichtshof.de/impressum" ,
      content: {
        de: {
          status: "Aktueller Status",
          statusNotSet: 'Noch keine Einwilligung erteilt.',
          statusConsent: 'Webanalyse akzeptiert.',
          statusNoConsent: 'Webanalyse abgelehnt.',
          btnAcceptWebTracking: 'Webanalyse akzeptieren',
          btnRejectWebTracking: 'Webanalyse ablehnen',
          legalNotice: 'Impressum',
          dataProtection: "Datenschutz",
          showCookiesInformation: 'Cookies Informationen anzeigen',
          hideCookiesInformation: 'Cookies Informationen ausblenden',
          matomoCookiesHeadline: 'Das Webanalyse-Tool Matomo verwendet folgende Cookies:',

          matomoCookieType: 'Typ',
          matomoCookieDescription: 'Beschreibung',
          matomoCookiePurpose: 'Zweck',
          matomoCookieDefaultExpiry: 'Speicherdauer',

          matomoCookieTypeTrackingCookie: 'Trackingcookie',
          matomoCookieTypeFunctionalCookie: 'Funktionscookie',

          matomoCookieDescriptionPkId: 'Dieses Cookie wird verwendet, um einige Details über den Websitebesucher zu speichern, wie zum Beispiel die eindeutige Besucher-ID.',
          matomoCookieDescriptionPkSes: 'Diese Cookies speichern kurzfristig Daten über den Besuch der Website (z.B. den Browserverlauf).',
          matomoCookieDescriptionPkRef: 'Dieses Cookie speichert die Referenz auf die Website, die einen Besucher auf die aktuelle Seite geführt hat.',
          matomoCookieDescriptionMtmConsent: 'Dieses Cookie dient der Speicherung der Einwilligung zum Tracking.',
          matomoCookieDescriptionMtmConsentRemoved: 'Dieses Cookie dient der Speicherung der Ablehnung des Trackings.',

          matomoCookiePurposeTracking: 'Tracking',
          matomoCookiePurposeFunctional: 'Funktional',
          matomoCookieDefaultExpiry12Months: '12 Monate',
          matomoCookieDefaultExpiry6Months: '6 Monate',
          matomoCookieDefaultExpiry30Minutes: '30 Minuten',
        },
        en: {
          status: "Current Status",
          statusNotSet: 'No consent given yet.',
          statusConsent: 'Web tracking accepted.',
          statusNoConsent: 'Web tracking rejected.',
          btnAcceptWebTracking: 'Accept web tracking',
          btnRejectWebTracking: 'Reject web tracking',
          legalNotice: 'Legal Notice',
          dataProtection: "Data Protection",
          showCookiesInformation: 'Show Cookies Information',
          hideCookiesInformation: 'Hide cookies information',
          matomoCookieType: 'Type',
          matomoCookieDescription: 'Description',
          matomoCookiePurpose: 'Purpose',
          matomoCookieDefaultExpiry: 'Default Expiry',

          matomoCookieTypeTrackingCookie: 'Trackingcookie',
          matomoCookieTypeFunctionalCookie: 'Funktionscookie',
          matomoCookiesHeadline: 'Web analytics tool Matomo uses following cookies:',

          matomoCookieDescriptionPkId: 'EN!! Dieses Cookie wird verwendet, um einige Details über den Websitebesucher zu speichern, wie zum Beispiel die eindeutige Besucher-ID.',
          matomoCookieDescriptionPkSes: 'EN!! Diese Cookies speichern kurzfristig Daten über den Besuch der Website (z.B. den Browserverlauf).',
          matomoCookieDescriptionPkRef: 'EN!! Dieses Cookie speichert die Referenz auf die Website, die einen Besucher auf die aktuelle Seite geführt hat.',
          matomoCookieDescriptionMtmConsent: 'EN!! Dieses Cookie dient der Speicherung der Einwilligung zum Tracking.',
          matomoCookieDescriptionMtmConsentRemoved: 'EN!! Dieses Cookie dient der Speicherung der Ablehnung des Trackings.',

          matomoCookiePurposeTracking: 'Tracking',
          matomoCookiePurposeFunctional: 'Functional',
          matomoCookieDefaultExpiry12Months: '12 months',
          matomoCookieDefaultExpiry6Months: '6 months',
          matomoCookieDefaultExpiry30Minutes: '30 minutes',
        },

      },
    };
  },
  computed: {
    // Computed property for conditional consent message
    // eslint-disable-next-line vue/return-in-computed-property
    consentMessage() {

      if (!this.matomoConsent) {
        return this.localizedContent('statusNotSet');
      } else if (this.matomoConsent === 'accepted') {

        return this.localizedContent('statusConsent');
      } else if (this.matomoConsent === 'rejected') {

        return this.localizedContent('statusNoConsent');
      }
    },


  },
  mounted() {
    // Check if the user has already made a choice about cookies
    this.matomoConsent = localStorage.getItem('matomoConsent');
    if (!this.matomoConsent) {
      this.showBanner = true;
      console.debug ("initial consent", this.matomoConsent);
    }
  },

  methods: {

    localizedContent: function (value) {
      return this.content[this.defaultLanguage][value];
    },
    toggleText() {
      this.isTextExpanded = !this.isTextExpanded;
    },
    acceptCookies() {
      // Set a flag in local storage to indicate matomo tracking is accepted
      localStorage.setItem('matomoConsent', 'accepted');
      this.matomoConsent = 'accepted';
      //change matomo settings
      // eslint-disable-next-line no-undef
      _paq.push(['rememberConsentGiven', 8760]);
      this.showBanner = false;
    },
    rejectCookies() {
      // Set a flag in local storage to indicate matomo tracking is rejected
      localStorage.setItem('matomoConsent', 'rejected');
      //localStorage.removeItem('matomoConsent'); //->only for debugging issues
      this.matomoConsent = 'rejected';

      //change matomo settings
      // eslint-disable-next-line no-undef
      _paq.push(['forgetConsentGiven']);
      this.showBanner = false;
    },
    showCookieBanner() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      console.debug ("consent", this.matomoConsent);
      this.showBanner = true;
    },
  },
};
</script>

<style>
</style>
