<template>
  <div class="assistant-v2" id="app">

    <div ref="assistant" v-bind:class="this.addClass" :data-current="this.currentQuestion.id">

      <div class="left">
        <div id="disclaimer-data-privacy" style="">
          <a href="#" title="Datenschutzeinstellungen ändern" style="" @click="showCookieBanner();">
            <span id="mydisclaimer" ></span>
          </a>
          <a :href=cookieBanner.data().impressumLink target="_blank" class="custom-link">{{this.localized('legalNotice')}}</a>
          <a :href=cookieBanner.data().datenschutzLink  target="_blank" class="custom-link"  style=" padding: 0.5em 0 0.2em 2.8em;">{{this.localized('dataProtection')}}</a>
          <a href="#"  @click="showCookieBanner()" class="custom-link"  style="padding: 0.5em 0 0.2em 2.8em;">{{this.localized('changeDataProtectionSettings1')}}<br>{{this.localized('changeDataProtectionSettings2')}} <br> {{this.localized('changeDataProtectionSettings3')}}</a></div>
      </div>

      <div class="center">
        <div id="outer-div" :style="{ visibility: visible ? 'visible' : 'hidden' }">
          <div class="progressBar">
              <div>
                <p v-html="this.progressBarOutputComputed"></p>
                <div class="bar">
                    <div class="value" v-bind:style="{ width: this.progressPercent + '%' }"></div>
                </div>
              </div>
          </div>

          <div class="content">

              <a class="helpIcon" href="#" @click="onShowHelp()" v-if="this.currentQuestion.help !== undefined && this.currentQuestion.help !== ''"><span>Help</span></a>

              <!--__{{this.currentQuestion.questionType}}__-->

              <!-- headline -->
              <h2 v-if="this.currentQuestion.headline !== undefined && this.currentQuestion.headline !== ''"
                v-html="this.currentQuestion.headline">
              </h2>
              <!-- subHeadline -->
              <h3
                v-if="this.currentQuestion.subHeadline !== undefined && this.currentQuestion.subHeadline !== ''"
                v-html="this.currentQuestion.subHeadline"
              >
              </h3>
              <!-- Description -->
              <div
                class="description" v-if="this.currentQuestion.description !== undefined && this.currentQuestion.description !== ''"
                v-html="this.currentQuestion.description"
              ></div>

              <!-- -->
              <div class="result" v-if="this.currentQuestion.questionType === 'resultQuestion'">
                <div>
                  <div v-html="this.clauseText">

                  </div>
                  <textarea type="text" id="clipboardText" :value="this.clauseTextClipboard"></textarea>
                  <div class="toolbar share" v-if="this.currentQuestion.noToolbar !== true">
                    <div>
                      <a href="javascript:void(0);" v-if="this.mailToLink !== ''" class="btnPrint" @click="printPage">
                        <span class="text-main">{{this.localized('printResult')}}</span><br>
                        <span class="text-sub">{{this.localized('saveAsPdf')}}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Errpr no selection -->
              <template v-if="this.errorSelect === true">
                <p class="error">{{ this.errorMsg }}</p>
              </template>

              <!-- answers YES/NO -->
              <div
                class="answers"
                v-if="this.currentQuestion.questionType === 'yesNoQuestion'"
              >
                <ul>
                  <li
                    ref="qYes"
                    class="qYes"
                    :data-target="this.currentQuestion.yesGoTo"
                    @click.prevent="onQuestionYes()"
                    v-html="this.currentQuestion.yesLabel"
                    v-bind:class="{ selected: this.yesToggle === true }"
                  ></li>
                  <li
                    ref="qNo"
                    class="qNo"
                    :data-target="this.currentQuestion.noGoTo"
                    @click.prevent="onQuestionNo()"
                    v-html="this.currentQuestion.noLabel"
                    v-bind:class="{ selected: this.noToggle === true }"
                  ></li>
                </ul>
              </div>

              <!-- multiQuestion -->
              <div
                  class="answers"
                  v-if="this.currentQuestion.questionType === 'multiQuestion'"
                  >
                  <ul v-for="(question, index) in this.currentQuestion.list" v-bind:key="index">
                      <li
                        :ref='index'
                        :data-index="index"
                        v-html="question.label"
                        :data-target="question.goTo"
                        @click.prevent="onMultiQuestionClick($event, question)"
                        >
                      </li>
                  </ul>
              </div>

              <!-- select IHK -->
              <div v-if="this.currentQuestion.questionType === 'selectQuestion'" class="selection">
                  <div>
                    <select @change="onChange($event)" v-bind:data-target="this.nextTarget" v-model="selectBox">
                        <option :value="-1">{{this.localized('noSelection')}}</option>
                        <template v-for="(item,index) in this.currentQuestion.selection">
                          <option :value="index" v-bind:key="index">{{item.name}}</option>
                        </template>
                    </select>
                  </div>
              </div>

              <div class="viewMode" v-bind:class="{ message: this.viewModeMessage }" v-if="this.viewMode === true && (this.currentQuestion.questionType === 'yesNoQuestion' || this.currentQuestion.questionType === 'multiQuestion')" @click.prevent="onClickViewMode()">
                <div>
                  <div>
                    <p>{{this.localized('newSelection')}}</p>
                    <a href="#" class="link" @click.stop.prevent="onViewModeCancel()">{{this.localized('newSelectionCancel')}}</a>
                    <a href="#" class="btn" @click.prevent="onViewModeQuit()">{{this.localized('newSelectionOk')}}</a>
                  </div>
                </div>
              </div>

              <a href="#" class="btnViewModeBackToResult" v-if="this.viewMode === true && (this.historyCurrentPos+1 < this.history.length)" @click.prevent="onBackToResult()">{{
                  this.localized('backToTheResult')
                }}</a>

          </div>
          <!-- content -->

          <template v-if="this.editModeActive === true">
            <div class="editInputArea">
              <h2>Texte bearbeiten</h2>
              <label for="">Überschrift 1</label>
              <input type="text" v-model="currentQuestionChanges.headline" onInputChange="onInputChange()">
              <label for="">Überschrift 2</label>
              <input type="text" v-model="currentQuestionChanges.subHeadline" onInputChange="onInputChange()">
              <label for="">Beschreibung</label>
              <quill-editor
                v-model="currentQuestionChanges.description"
                :options="editorOption"
                class="ql-align"
              />
              <br />

              <template v-if="this.currentQuestionChanges.questionType === 'selectQuestion'">
                <label for="#">Kammer Auswahl List</label>
                <input type="text" v-model="selectedChamber">
                <span class="info">Füngen Sie eine neue IHK hinzu oder wählen Sie eine aus der Liste aus, um die Kammer zu bearbeiten, zu löschen oder zu verschieben.</span>
                <!--
                  <button class="btn small" @click.prevent="onLoginAction()">Anmelden</button>
                -->
                <button class="btn small" @click.prevent="addChamber()">Hinzufügen</button>
                <template v-if="this.isSelected">
                  <button class="btn small" @click.prevent="updateChamber()">Ändern</button>
                  <button class="btn small" @click.prevent="deleteChamber()">Entfernen</button>
                  <button class="btn small" @click.prevent="swapChamber('up')">hoch</button>
                  <button class="btn small" @click.prevent="swapChamber('down')">runter</button>
                </template>
                <br />
                <hr />
                <ul v-for="(item, index) in this.currentQuestionChanges.selection" v-bind:key="index">
                    <li :data-index="index" @click.prevent="onClickSelectChamber($event)">{{item.name}}</li>
                </ul>
                <br />
                <br />
              </template>

              <template v-if="this.currentQuestionChanges.questionType === 'yesNoQuestion'">
                <label for="">Auswahl A</label>
                <quill-editor
                  v-model="currentQuestionChanges.yesLabel"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />

                <label for="">Auswahl B</label>
                <quill-editor
                  v-model="currentQuestionChanges.noLabel"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />
              </template>

              <template v-if="this.currentQuestionChanges.questionType === 'multiQuestion'">
                <label>Auswahl A</label>
                <quill-editor
                  v-model="currentQuestionChanges.list['question1']['label']"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />
                <label>Auswahl B</label>
                <quill-editor
                  v-model="currentQuestionChanges.list['question2']['label']"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />
                <label>Auswahl C</label>
                <quill-editor
                  v-model="currentQuestionChanges.list['question3']['label']"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />

              </template>

              <label for="">Hilfe</label>
              <quill-editor
                v-model="currentQuestionChanges.help"
                :options="editorOption"
                class="ql-align"
              />
              <br />

              <template v-if="this.currentQuestionChanges.questionType === 'resultQuestion'">
                <label for="">Klausel</label>
                <quill-editor
                  v-model="currentQuestionChanges.clause"
                  :options="editorOption"
                  class="ql-align"
                />
                <br />
              </template>
            </div>
          </template>

          <!-- toolbar -->
          <div class="toolbar">
            <div  v-if="this.editModeActive !== true">
              <button class="btn back" v-if="this.historyCurrentPos > 0" @click="onBack()">{{this.localized('btnPrevious')}}</button>
              <div >
              <button
                class="btn next"
                v-bind:data-target="this.nextTarget"
                @click.self="onNext($event)"
                v-bind:class="{ disabled: this.nextTarget === '' }"
                v-if="(this.currentQuestion.questionType !== 'resultQuestion') || (this.currentQuestion.questionType === 'resultQuestion' && this.nextTarget !== '')"
              >
                {{this.localized('btnNext')}}
              </button>
              </div>
            </div>
          </div>
          <!-- toolbar -->

        </div>
      </div>

      <div class="right">
        <div>

          <div class="help">
            <div>
                <!-- Help Text -->
                <div class="help" v-if="this.currentQuestion.help !== undefined && this.currentQuestion.help !== ''">
                  <div v-if="this.toggleShowHelp === true">
                    <p class="notice"><strong>{{ this.localized('helpHeadline') }}</strong></p>
                    <div v-html="this.currentQuestion.help">
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <!-- history Debug -->
          <div class="history">
            <div>
                {{ this.currentQuestion.questionType }}
            </div>
            <div>
                <strong>Debug - Click History</strong>
            </div>
            <div  v-for="(item, index) in this.history" v-bind:key="index">
                <div>{{item.id}}</div>
            </div>
          </div>
          <!-- history Debug -->

        </div>
      </div>
    </div>


    <div>
      <div class="left">
<!--        <div id="disclaimer-data-privacy" style="">
          <a href="#" title="Einstellungen zum Datenschutz" style="" @click="showCookieBanner();">
            <span id="mydisclaimer" ></span>
          </a>
          <a href="https://schiedsgerichtshof.de/impressum/" target="_blank" class="custom-link" title="Impressum des Konfliktnavigators">Impressum</a>
          <a href="https://schiedsgerichtshof.de/datenschutzerklaerung/" target="_blank" class="custom-link"  style=" padding: 0.2em 0;
" title="Datenschutz des Konfliktnavigators">Datenschutz</a></div>-->

      </div>
      <div>
        <CookieBanner
            ref="cookieBanner"
            :defaultLanguage="defaultLanguage"
        />


      </div>
      <div class="center">
        <div>

          <div class="steps" v-if="this.currentQuestion.questionType === 'resultQuestion'">
            <div>
              <h2>{{this.localized('summaryHeadline')}}</h2>
              <template v-for="(item, index) in this.history">
                <ul v-if="item.questionType === 'yesNoQuestion' || item.questionType === 'multiQuestion' || item.questionType === 'selectQuestion'" :key="index">
                  <li>
                    <div class="question">
                      <div>
                        <div><span>F</span>:</div>
                        <div>

                        <div style="font-weight: bold; padding-left: 10px" v-html="item.headline"
                             v-if="item.headline !== undefined && item.headline !== ''"></div>
<!--
                        <div>{{ logItem(item) }}</div>
-->
                        <div style="font-weight: bold; padding-left: 10px;"
                             v-html="item.description"
                             v-if="item.description !== undefined && item.description !== ''"></div>
                        </div>
                      </div>
                    </div>

                    <div class="answer">
                      <div>
                        <div><span>A</span>:</div>
                        <div v-html="item.selection"
                             v-if="item.selection !== undefined && item.selection !== ''"></div>
                      </div>
                    </div>
                    <a class="left" href="#" :data-target="item.id"
                       @click.prevent="onBackToQuestion($event)">
                      {{localized('backToQuestion')}}
                    </a>
                  </li>
                </ul>
              </template>

                <button
                  class="btn next"
                  @click="onNewKonfig($event)"
                >
                  <span v-if="this.btnStartNewAgree === true" >{{this.localized('yes')}} </span>
                  {{this.localized('btnStartNewText')}}
                </button>
                <p class="newStartAgree" v-if="this.btnStartNewAgree === true">
                  {{this.localized('questionByStartOfNewTest')}}</p>
            </div>
          </div>

          <template v-if="this.editModeActive === false && this.isLoginOk === true">
            <div v-for="(path, index) in this.paths" v-bind:key="index" style="font-size: 11px;">
              {{ path }}
            </div>
          </template>

        </div>
      </div>

      <div class="right">
        <div>

          <span style="color:lightgray;visibility: hidden;">{{ this.currentQuestion.id }}</span>

        </div>
      </div>

    </div>

    <div>
      <div>

        <!--
        <div
          v-for="(oneQuestions, index) in this.questions"
          :key="index"
          style="font-size: 11px; border: 1px solid gray; margin-bottom: 24px; padding: 12px;"
        >

          <strong v-if="oneQuestions.id !== ''" v-html="oneQuestions.id" style="font-size: 20px;"></strong>
          <br />
          <br />

          <strong v-if="oneQuestions.headline !== ''">Headline:</strong>
          <br />
          <p v-if="oneQuestions.headline !== ''" v-html="oneQuestions.headline"></p>

          <strong v-if="oneQuestions.subHeadline === null">subHeadline:</strong>
          <br />
          <p v-if="oneQuestions.subHeadline === null" v-html="oneQuestions.subHeadline"></p>

          <strong v-if="oneQuestions.description !== ''">description:</strong>
          <br />
          <p v-if="oneQuestions.description !== ''" v-html="oneQuestions.description"></p>

          <strong v-if="oneQuestions.help !== ''">help:</strong>
          <br />
          <p v-if="oneQuestions.help !== ''" v-html="oneQuestions.help"></p>

          <strong v-if="oneQuestions.clause === null">clause:</strong>
          <br />
          <p v-if="oneQuestions.clause === null" v-html="oneQuestions.clause"></p>

          <hr />
          <br />

          <strong v-if="oneQuestions.noLabel !== ''">noLabel:</strong>
          <br />
          <p v-if="oneQuestions.noLabel !== ''" v-html="oneQuestions.noLabel"></p>

          <strong v-if="oneQuestions.yesLabel !== ''">yesLabel:</strong>
          <br />
          <p v-if="oneQuestions.yesLabel !== ''" v-html="oneQuestions.yesLabel"></p>

          <strong v-if="oneQuestions.clause !== ''">clause:</strong>
          <br />
          <p v-if="oneQuestions.clause !== ''" v-html="oneQuestions.clause"></p>

        </div>
        -->

      </div>
    </div>
    <div class="loadingError" style="position:absolute" v-if="this.loadingError===true">
      <p>
        Requested page could not be found. The page should either end with <b>/?type=nachtraeglich</b> or with <b>/?type=praeventiv</b>.
      </p>
    </div>
    <div class="disclaimer" v-if="!this.disclaimerAgreeDone && !this.loadingError" >
      <div>
          <h2 style="font-weight: bold;">Disclaimer</h2>
          <div v-html="this.localized('disclaimerText')">
          </div>
        <p>
            <input type="checkbox" id="agree" v-model="disclaimerAgree" />
          <label for="agree" v-html="this.localized('disclaimerLinkText')" v-bind:class="{red: this.disclaimernotAgreeYet === true && this.disclaimerAgree !== true}"></label>
        </p>
        <div class="toolbar">
          <div>
              <button class="btn next" v-bind:class="{disabled: this.disclaimerAgree === false}" @click.prevent="onClickDisclaimerAgreeDone()">
                  {{this.localized('disclaimerBtnText')}}
              </button>
          </div>
        </div>
      </div>
    </div>

    <div class="edit" v-if="this.loginParam === true">
      <div>
        <!-- if Edit mode -->
        <template v-if="this.isLoginOk === true">
          <span>Edit Toolbar / {{this.loginParam}} </span>
        </template>

        <!-- login accessible -->
        <div class="login">
            <a href="#" title="Login" @click.prevent="onLoginDlg()">
              <img src="/images/login.png" alt="Login">
            </a>
            <div class="dlg" v-if="this.loginToggle === true">
    <div>
      <template v-if="this.isLoginOk === false">
        <label for="user">Benutzer</label>
        <input name="user" id="user" v-model="user" type="text" placeholder="Benutzer" />
        <label for="pw">Passwort:</label>
        <input name="pw" id="pw" v-model="pw" type="password">
        <button class="btn small" @click.prevent="onLoginAction()">Anmelden</button>
      </template>
      <template v-if="this.loginError === true">
        <br />
        <p v-html="this.loginMsg" style="color: #cc0000;"></p>
      </template>
      <template v-if="this.isLoginOk === true">
        <p>Login erfolgreich!</p>
        <button class="btn small" @click.prevent="onLoginDlg()">Schliessen</button>
        <button class="btn small" @click.prevent="onLogoutDlg()">Abmelden</button>
      </template>
    </div>
  </div>

            </div>
        </div>

        <template v-if="this.isLoginOk === true">
          <div lass="buttons">
            <template v-if="this.disclaimerAgreeDone === true">
              <template v-if="this.editModeActive === false">
                <select
                  name="idList"
                  id="idList"
                  v-model="editQuestionIdSelect"
                  @change="onChangeEditQuerstionId()"
                  style="display:inline-block; margin-right: 6px; padding: 2px 4px;"
                >
                  <option v-for="(question) in this.questionIdList" :key="question" :value="question">
                    {{ question }}
                  </option>
                </select>
              </template>
            </template>
            <template v-if="this.disclaimerAgreeDone === true">
              <button class="btn small" @click.prevent="onActivateEditModus()" v-if="this.editModeActive === false"> Bearbeiten </button>
            </template>
            <button class="btn small" @click.prevent="onSaveChanges()" v-if="this.editModeActive === true"> Änderung speichern </button>
            <button class="btn small" @click.prevent="onDeactivateEditModus()"  v-if="this.editModeActive === true"> Abbrechen </button>
          </div>
        </template>

      </div>

    </div>
</template>

<script >

import axios from 'axios'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import CookieBanner from './components/CookieBanner.vue';
import cookieBanner from "@/components/CookieBanner.vue";

export default {
  name: 'app',
  computed: {
    progressBarOutputComputed: function () {
      const tempHistoryCurrentPos = this.historyCurrentPos + 1
      if (this.minMax[0] !== this.minMax[1]) {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('stillText') + ' ' + this.localized('maxText') + ' ' + this.minMax[1] +  this.localized('stepsText') + ')</i>'
      } else if ((this.minMax[0] === this.minMax[1]) && (this.minMax[1] !== 0)) {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('stillText') + ' ' + this.minMax[1] + ')</i>'
      } else {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('ofText') + '  ' + this.history.length + ')</i>'
      }
    },
    cookieBanner() {
      return cookieBanner
    }

  },
  components: {
    quillEditor, CookieBanner,
  },

  methods: {
    logItem(item) {
      console.log("history in the end :", item);
    },
    showCookieBanner() {
      this.$refs.cookieBanner.showCookieBanner();
    },
    printPage() {
      window.print();
    },
    removeHtmlTags(input) {
      let textWithoutTags = input.replace(/<[^>]*>/g, ' ');
      let cleanedText =  textWithoutTags.replace(/&nbsp;/g, ' ');
      let truncatedText = cleanedText.trim().substring(0, 40);
      if (cleanedText.length > 40) {
        truncatedText += '...';
      }
      return truncatedText;
    },
    onQuestionYes: function () {
      this.noToggle = false
      this.yesToggle = !this.yesToggle

      this.selectedQuestionDescription = this.currentQuestion.description;
      this.selectedQuestionLabel = this.currentQuestion.yesLabel;
      // history check
      const historyValue = this.history[this.historyCurrentPos].value
      if ((historyValue === 'no') && (this.yesToggle === true)) {
        // all following history enties > remove
        this.history.splice(this.historyCurrentPos + 1, this.history.length - (this.historyCurrentPos + 1))
      }

      if (this.yesToggle === true) {
        this.nextTarget = this.$refs.qYes.attributes['data-target'].nodeValue
        let current = this.$refs.assistant.attributes['data-current'].nodeValue
        this.questions[current].yesSelected = true
        this.questions[current].noSelected = false
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = this.questions[current].yesLabel
        this.history[historyIndex].value = 'yes'
      } else {
        this.nextTarget = ''
        let current = this.$refs.assistant.attributes['data-current'].nodeValue
        this.questions[current].yesSelected = false
        this.questions[current].noSelected = false
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = ''
      }
    },
    onQuestionNo: function () {
      this.yesToggle = false
      this.noToggle = !this.noToggle

      this.selectedQuestionDescription = this.currentQuestion.description;
      this.selectedQuestionLabel = this.currentQuestion.noLabel;

      let historyValue = this.history[this.historyCurrentPos].value
      if ((historyValue === 'yes') && (this.noToggle === true)) {
        // all following history enties > remove
        this.history.splice(this.historyCurrentPos + 1, this.history.length - (this.historyCurrentPos + 1))
      }

      if (this.noToggle) {
        this.nextTarget = this.$refs.qNo.attributes['data-target'].nodeValue
        let current = this.$refs.assistant.attributes['data-current'].nodeValue
        this.questions[current].noSelected = true
        this.questions[current].yesSelected = false
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = this.questions[current].noLabel
        this.history[historyIndex].value = 'no'
      } else {
        this.nextTarget = ''
        let current = this.$refs.assistant.attributes['data-current'].nodeValue
        this.questions[current].noSelected = false
        this.questions[current].yesSelected = false
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = ''
      }
    },
    onMultiQuestionClick: function (event, question) {
      this.selectedQuestionDescription = this.currentQuestion.description;
      this.selectedQuestionLabel = question.label;

      let index = event.currentTarget.attributes['data-index'].nodeValue
      let key = index.replace('question', '')
      key = key - 1
      let elem = this.$refs[index][0]

      let historyValue = this.history[this.historyCurrentPos].value
      if (key !== historyValue) {
        // all following history enties > remove
        this.history.splice(this.historyCurrentPos + 1, this.history.length - (this.historyCurrentPos + 1))
      }
      // eslint-disable-next-line no-undef
      // _paq.push(['trackEvent', 'Clicked','Click', key]);


      let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
      if (elem.className.match(/\bselected\b/)) {
        elem.classList.remove('selected')
        this.nextTarget = ''
        this.history[historyIndex].selection = ''
        this.history[historyIndex].value = -1
      } else {
        for (var item in this.$refs) {
          if (item.indexOf('question') >= 0) {
            this.$refs[item][0].classList.remove('selected')
          }
        }
        elem.classList.add('selected')
        this.nextTarget = elem.attributes['data-target'].nodeValue
        this.history[historyIndex].selection = this.currentQuestion.list[index].label
        this.history[historyIndex].value = key
      }
    },
    onNext: function (event) {
      if (this.currentQuestion.questionType === 'selectQuestion' && this.selectBox >= 0) {

       let selectedIHK = this.currentQuestion.selection[this.selectBox].name;
      console.log("Selected IHK1:", selectedIHK);
      console.log("headline: ", this.currentQuestion.headline);
        // eslint-disable-next-line no-undef
        _paq.push(['trackEvent', this.typeParam, this.currentQuestion.headline, selectedIHK, 1]);

    }
      let nextTarget = event.target.attributes['data-target'].nodeValue
      if (nextTarget !== '') {
        this.newQuestion = true
        this.setNewQuestionValues(nextTarget)
        this.updateHistory('next', -1)

        let result = this.questions[nextTarget].result;
        if (result !== "") {
          console.log("pushing result", result);
          // eslint-disable-next-line no-undef
          _paq.push(['trackEvent', this.typeParam, "Ergebnis", result, 1]);
        }

       // _paq.push(['trackEvent', this.typeParam, this.selectedQuestionDescription,  this.selectedQuestionDescription, this.selectedQuestionLabel]);
        //_paq.push(['trackEvent', this.typeParam, 'Question selection', this.selectedQuestionDescription, this.selectedQuestionLabel]);

/*                  'trackEvent',   Category,    Action,           Name,         Value: */
        //_paq.push(['trackEvent', 'eCommerce', 'Add to Wishlist', 'Smartphone', 1000]);


      } else {
        this.errorSelect = true
      }
    },
    onBack: function () {
      this.lastQuestion = true
      this.updateHistory('back', -1)
      let lastQuestionId = this.history[this.historyCurrentPos].id
      this.setNewQuestionValues(lastQuestionId)
    },
    onBackToQuestion: function (event) {
      this.lastQuestion = true
      let id = event.target.attributes['data-target'].nodeValue
      this.updateHistory('resultBack', id)
      this.setNewQuestionValues(id)
    },
    onBackToResult: function () {
      this.lastQuestion = true
      let id = this.history[this.history.length - 1].id
      this.updateHistory('resultBack', id)
      this.nextTarget = ''
      this.setNewQuestionValues(id)
    },
    setNewQuestionValues: function (id) {
      this.currentQuestion = this.questions[id];
    },
    updateHistory: function (direction, id) {

      if (this.historyCurrentPos === 0) {
        let strTemp = ''
        if (this.questions['q001'].headline === '') {
          strTemp = this.questions['q001'].description
        } else {
          strTemp = this.questions['q001'].headline
        }
        this.history[this.historyCurrentPos].headline = strTemp;
      }


      if (direction === 'next') {
        this.historyCurrentPos++
        if (this.history[this.historyCurrentPos] === undefined) {
          // New - add Entry to history
          var selection = ''
          var value = '-1'
          const tempInfos = { id: this.currentQuestion.id, questionType: this.currentQuestion.questionType, headline: this.currentQuestion.headline, description: this.currentQuestion.description, selection: selection, value: value, linkText: this.localized('backToQuestion') }
          this.history.push(tempInfos)
        }
      }
      if (direction === 'back') {
        this.historyCurrentPos--
      }
      if (direction === 'resultBack') {
        let historyIndex = this.findHistoryEntry(id)
        this.historyCurrentPos = historyIndex
      }

    },
    findHistoryEntry: function (questionId) {
      for (let i = 0; i < this.history.length; i++) {
        if (questionId === this.history[i].id) {
          return i
        }
      }
      return -1
    },
    onChange: function (event) {
      if (event.target.value !== '-1') {
        if (this.currentQuestion.twoIhkReviewer === true) {
          this.ihkReviewerTwo = this.currentQuestion.selection[event.target.value].name
        } else {
          this.ihkReviewerOne = this.currentQuestion.selection[event.target.value].name
        }
        this.nextTarget = this.currentQuestion.justGoTo
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = this.ihkReviewerOne + ' ' + this.ihkReviewerTwo
        this.history[historyIndex].value = event.target.value
      } else {
        this.ihkReviewerOne = ''
        this.ihkReviewerTwo = ''
        this.nextTarget = ''
        let historyIndex = this.findHistoryEntry(this.currentQuestion.id)
        this.history[historyIndex].selection = ''
        this.history[historyIndex].value = -1
      }
    },
    onShowHelp: function () {
      this.toggleShowHelp = !this.toggleShowHelp
    },
    onNewKonfig: function () {
      if (this.btnStartNewAgree === true) {
        this.btnStartNewAgree = false
        this.newQuestion = true
        this.lastQuestion = false
        this.selectBox = -1
        this.history = []
        this.historyCurrentPos = 0
        this.viewMode = false
        this.viewModeMessage = false
        this.currentQuestion = this.questions['q001']
        let tempInfos = { 'id': this.currentQuestion.id, 'questionType': this.currentQuestion.questionType, 'headline': this.currentQuestion.headline, 'description': this.currentQuestion.description, 'selection': '', 'value': '', linkText: this.localized('backToQuestion')  }
        this.history.push(tempInfos)
        this.initProgressBar()
      } else {
        this.btnStartNewAgree = true
      }
    },
    onCopyToClipboard: function () {
      /* Get the text field */
      var copyText = document.getElementById('clipboardText')
      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      /* Copy the text inside the text field */
      document.execCommand('copy')
      /* Alert the copied text */
      alert('Text : ' + copyText.value)
    },
    createMailToLink: function (clause) {
      const encodedBody = encodeURIComponent(clause);
      return 'mailto:?subject=IHK-Konfliktnavigator&body=' + encodedBody
    },
    getCurrentDate: function () {
      let d = new Date()
      let fornattedToString = ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth() + 1)).slice(-2) + '.' + d.getFullYear() + ', ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
      return fornattedToString
    },
    onGoTo: function () {
      this.setNewQuestionValues(this.questionID)
    },
    onClickGoTo: function () {
      this.setNewQuestionValues('q012')
    },
    onChangeEditQuerstionId: function () {
      this.setNewQuestionValues(this.editQuestionIdSelect)
    },
    onClickDisclaimerAgreeDone: function () {
      this.disclaimernotAgreeYet = true
      if (this.disclaimerAgree === true) {
        this.disclaimerAgreeDone = true
        this.visible=true;
      }
    },
    getSuccessors: function (question) {
      let successors = []
      for (var i = 0; i < this.links.length; i++) {
        if (question[this.links[i]] !== '') {
          successors.push(question[this.links[i]])
        }
      }
      for (var key in question.list) {
        const successor = question.list[key].goTo
        if (successor !== '') {
          successors.push(successor)
        }
      }
      return successors
    },
    topoSort: function (questions) {
      const vertices = Object.keys(questions)
      let incoming = {}
      for (let i = 0; i < vertices.length; i++) {
        incoming[vertices[i]] = 0
      }
      let left = {}
      for (let i = 0; i < vertices.length; i++) {
        const vertex = vertices[i]
        const question = questions[vertex]
        const successors = this.getSuccessors(question)
        left[vertex] = successors.length
        for (let j = 0; j < successors.length; j++) {
          incoming[successors[j]]++
        }
      }
      let queue = []
      for (let i = 0; i < vertices.length; i++) {
        if (incoming[vertices[i]] === 0) {
          queue.push(vertices[i])
        }
      }
      let sorted = []
      while (queue.length > 0) {
        const vertex = queue.shift()
        const question = questions[vertex]
        sorted.push(vertex)
        let successors = this.getSuccessors(question)
        for (let i = 0; i < successors.length; i++) {
          const successor = successors[i]
          incoming[successor]--
          if (incoming[successor] === 0) {
            queue.push(successor)
          }
        }
      }
      return sorted
    },
    minMaxLen: function (questions, sorted, source) {
      let remaining = Array.from(sorted)
      while (remaining[0] !== source) {
        remaining.shift()
      }
      let minLengths = {}
      let maxLengths = {}
      minLengths[remaining[0]] = 0
      maxLengths[remaining[0]] = 0
      let leaves = []
      for (let i = 0; i < remaining.length; i++) {
        const vertex = remaining[i]
        if (minLengths[vertex] !== undefined) {
          const question = questions[vertex]
          const successors = this.getSuccessors(question)
          if (successors.length === 0) {
            leaves.push(vertex)
          } else {
            for (let j = 0; j < successors.length; j++) {
              const successor = successors[j]
              if (
                minLengths[successor] === undefined ||
                      minLengths[successor] > minLengths[vertex] + 1
              ) {
                minLengths[successor] = minLengths[vertex] + 1
              }
              if (
                maxLengths[successor] === undefined ||
                      maxLengths[successor] < maxLengths[vertex] + 1
              ) {
                maxLengths[successor] = maxLengths[vertex] + 1
              }
            }
          }
        }
      }
      let minimum = Infinity
      let maximum = 0
      for (let i = 0; i < leaves.length; i++) {
        let minLength = minLengths[leaves[i]]
        if (minLength < minimum) {
          minimum = minLength
        }
        let maxLength = maxLengths[leaves[i]]
        if (maxLength > maximum) {
          maximum = maxLength
        }
      }
      return [minimum, maximum]
    },
    getGraph: function (questions) {
      const graph = {}
      for (const vertex in questions) {
        graph[vertex] = this.getSuccessors(questions[vertex])
      }
      return graph
    },
    findPaths: function (graph, vertex) {
      const successors = graph[vertex]
      if (successors.length) {
        return successors
          .map(x => this.findPaths(graph, x).map(y => [vertex].concat(y)))
          .flat()
      } else {
        return [[vertex]]
      }
    },
    findPathsFromSource: function (questions, source) {
      const graph = this.getGraph(questions)
      return this.findPaths(graph, source)
    },
    initProgressBar: function () {
      this.minMax = this.minMaxLen(this.questions, this.sorted, this.currentQuestion.id)
      this.progressPercent = this.progressInPercent(this.minMax[1] + this.history.length, this.history.length)
      this.progressOutput = this.progressBarOutput()
    },
    progressInPercent: function (max, current) {
      return (100 / max) * current
    },
    progressBarOutput: function () {
      let tempHistoryCurrentPos = this.historyCurrentPos + 1
      if (this.minMax[0] !== this.minMax[1]) {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('stillText') + ' ' + this.localized('maxText') + ' ' + this.minMax[1] + this.localized('stepsText') + ')</i>'
      } else if ((this.minMax[0] === this.minMax[1]) && (this.minMax[1] !== 0)) {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('stillText') + ' ' + this.minMax[1] + ')</i>'
      } else {
        return '<strong>' + this.localized('stepText') + ' ' + tempHistoryCurrentPos + '</strong> <i>(' + this.localized('ofText') + '  ' + this.history.length + ')</i>'
      }
    },
    onClickViewMode: function () {
      this.viewModeMessage = true
    },
    onViewModeCancel: function () {
      this.selectBox = -1
      this.viewModeMessage = false
    },
    onViewModeQuit: function () {
      this.viewMode = false
    },
    getData: function (type) {
      let tempEndpoint = '';
      let dataPathPart = 'data/' + type;
      //choose between local and remote settings
/*      start remote settings */
        tempEndpoint = process.env.VUE_APP_SERVICE_PATH + dataPathPart + '?language=' + this.defaultLanguage;
/*        end remote settings */

/*       start local settings  */
/*       if (type === 'nachtraeglich') {
        tempEndpoint = dataPathPart + "/" + this.localDataNachtraeglich;
      } else {
        tempEndpoint = dataPathPart + "/" + this.localDataPraeventiv;
      }*/
/*       end local settings */
      return tempEndpoint;
    },

    fetchQuestions: function () {

      let tempEndpoint = '';
      if (this.typeParam === 'nachtraeglich' || this.typeParam === 'praeventiv') {
        tempEndpoint = this.getData (this.typeParam);
        console.log("Receiving data from:", tempEndpoint);

      } else {
          this.loadingError= true;
          tempEndpoint = null;
          console.log ("No data provided");
      }
           axios.get(tempEndpoint)
          .then(response => {
          this.questions = response.data

          for (const key in this.questions) {
            if (this.questions[key].questionType === 'resultQuestion') {
              this.questionIdList.push(key + '- kein GoTo Möglich')
            } else {
              this.questionIdList.push(key)
            }
          }

          this.initQuestions()

          // console.log(this.questions)
          this.paths = this.findPathsFromSource(this.questions, 'q001')

          /*
          for (var q in this.questions) {
            if (this.questions[q].questionType === 'infoQuestion') {
              console.log(this.questions[q].id)
            }
          }

          for (var i = 0; i < paths.length; i++) {
            for (var j = 0; j < paths[i].length; j++) {
              let item = this.questions[paths[i][j]]
              if (item.headline !== '') {
                console.log(item.id + '_' + item.headline + '_')
              }
            }
          }
          */
        })
        .catch(e => {
          console.log("error", e);
        })
    },
    initQuestions: function () {
      this.currentQuestion = this.questions['q001']
      this.sorted = this.topoSort(this.questions)
      this.initProgressBar()
    },
    gup (name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      var regexS = '[\\?&]' + name + '=([^&#]*)'
      var regex = new RegExp(regexS)
      var results = regex.exec(window.location.href)
      if (results == null) {
        return ''
      } else {
        return results[1]
      }
    },
    onLoginDlg: function () {
      this.loginToggle = !this.loginToggle
    },
    onLogoutDlg: function () {
      this.loginToggle = !this.loginToggle
      this.isLoginOk = false
      this.t = ''
      this.loginError = false
      this.loginMsg = ''
      this.editModeActive = false
    },
    onLoginAction: function () {
      // POST request using axios with error handling
      const infos = { 'username': this.user, 'password': this.pw }
      axios.post(process.env.VUE_APP_SERVICE_PATH + 'authenticate/', infos)
        .then(response => {
          if (response.data['message'] === 'ok') {
            if (response.data['token'] !== undefined) {
              this.isLoginOk = true
              this.t = response.data['token']
              this.loginError = false
              this.loginMsg = ''
            }
          } else if (response.data['message'] === 'Ihr Account wurde wegen h&auml;ufiger Fehleingaben vorr&uuml;bergehend gesperrt. Bitte versuchen Sie es in wenigen Minuten erneut.') {
            this.isLoginOk = false
            this.t = ''
            this.loginError = true
            this.loginMsg = 'Ihr Account wurde wegen häufiger <br />Fehleingabe kurzzeitig gesperrt'
          } else {
            this.isLoginOk = false
            this.t = ''
            this.loginError = true
            this.loginMsg = 'Anmeldung ist fehlgeschlagen. <br />Falscher Benutzername oder falsches Passwort'
          }
        })
        .catch(error => {
          this.errorMsg = error.errorMsg
          this.isLoginOk = false
          this.loginError = true
        })
        /* if (error.response.status === 401) {
        this.isLoginOk = false
        this.loginError = true
        this.loginMsg = 'Anmeldung ist fehlgeschlagen. Falscher Benutzername oder falsches Passwort.'
      } else {
        // Handle other errors as needed
        this.errorMsg = error.message
      } */
    },
    onActivateEditModus: function () {
      this.editModeActive = true
      this.selectedChamber = ''
      this.selectedchamberIndex = -1
      this.currentQuestionChanges = this.currentQuestion
      this.currentQuestionBackup = null
      this.currentQuestionBackup = Object.assign({}, this.currentQuestion)
    },
    onDeactivateEditModus: function () {
      this.editModeActive = false
      this.selectedChamber = ''
      this.selectedchamberIndex = -1
      this.currentQuestion = Object.assign({}, this.currentQuestionBackup)
      this.currentQuestionChanges = Object.assign({}, this.currentQuestionBackup)
    },
    onInputChange: function () {
      // this.currentQuestion = this.currentQuestionChanges
    },
    onSaveChanges: function () {
      // save change in json file
      let tempId = this.currentQuestion.id
      this.questions[tempId] = this.currentQuestion
      // Call Service and store data changes
      // save to service
      this.saveChangesToService()
    },
    saveChangesToService: function () {
      let tempEndpoint = ''
      if (this.typeParam === 'nachtraeglich') {
        tempEndpoint = process.env.VUE_APP_SERVICE_PATH + 'data/nachtraeglich' + '?language=' + this.defaultLanguage;
      } else {
        tempEndpoint = process.env.VUE_APP_SERVICE_PATH + 'data/praeventiv' + '?language=' + this.defaultLanguage;
      }

      axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.t
        }
      })
        .put(tempEndpoint, this.questions)
        .then(() => {
          this.editModeActive = false
/*
          this.errorMsg = res
*/
          this.errorMsg = null

        })
          .catch(err => {
        console.error("Error making PUT request:", err)
        if (err.response) {
          // Server responded with a status other than 2xx
          this.errorMsg = `Error ${err.response.status}: ${err.response.data}`;
        } else if (err.request) {
          // Request was made but no response was received
          this.errorMsg = 'No response received from server.';
        } else {
          // Something happened in setting up the request
          this.errorMsg = `Error: ${err.message}`;
        }
      });
    },
    addChamber: function () {
      if (this.selectedChamber !== '') {
        let tempEntry = { name: this.selectedChamber, link: '' }
        this.currentQuestionChanges.selection.push(tempEntry)
        this.selectedChamber = ''
        this.selectedchamberIndex = -1
        this.isSelected = false
      }
      // this.currentQuestion
    },
    updateChamber: function () {
      if (this.selectedchamberIndex !== -1) {
        this.currentQuestionChanges.selection[this.selectedchamberIndex]['name'] = this.selectedChamber
      }
    },
    deleteChamber: function () {
      if (this.selectedchamberIndex !== -1) {
        this.currentQuestionChanges.selection.splice(this.selectedchamberIndex, 1)
        this.selectedChamber = ''
        this.selectedchamberIndex = -1
        this.isSelected = false
      }
    },
    onClickSelectChamber: function (event) {
      this.selectedchamberIndex = parseInt(event.target.getAttribute('data-index'), 10)
      this.selectedChamber = this.currentQuestionChanges.selection[this.selectedchamberIndex]['name']
      this.isSelected = true
    },
    swapChamber: function (direction) {
      if (direction === 'up') {
        if (this.selectedchamberIndex !== -1) {
          if (this.selectedchamberIndex !== 0) {
            const tmp = this.currentQuestionChanges.selection[this.selectedchamberIndex]
            this.currentQuestionChanges.selection[this.selectedchamberIndex] = this.currentQuestionChanges.selection[this.selectedchamberIndex - 1]
            this.currentQuestionChanges.selection[this.selectedchamberIndex - 1] = tmp
            this.selectedchamberIndex = this.selectedchamberIndex - 1
            this.$forceUpdate()
          }
        }
      } else {
        if (this.selectedchamberIndex !== -1) {
          let listLength = this.currentQuestionChanges.selection.length
          if (listLength - 1 !== this.selectedchamberIndex) {
            const tmp = this.currentQuestionChanges.selection[this.selectedchamberIndex]
            this.currentQuestionChanges.selection[this.selectedchamberIndex] = this.currentQuestionChanges.selection[this.selectedchamberIndex + 1]
            this.currentQuestionChanges.selection[this.selectedchamberIndex + 1] = tmp
            this.selectedchamberIndex = this.selectedchamberIndex + 1
            this.$forceUpdate()
          }
        }
      }
    },
    localized: function (value) {
      return this.appText[this.defaultLanguage][value]
    },

  },


  created: function () {
    const urlParams = new URLSearchParams(window.location.search);
    this.typeParam = this.gup('type')
    if (this.typeParam === '' || this.typeParam === undefined) {
      //redirect to "nachtraeglich if type is not specified
      this.typeParam = 'nachtraeglich'
      urlParams.set('type', 'nachtraeglich');
      window.location.href = `${window.location.pathname}?${urlParams.toString()}`;
    }

    let sLanguage = this.gup('language')
    if (sLanguage === '' || sLanguage === undefined) {
      this.defaultLanguage = 'de'
    } else {
      if (sLanguage === 'en') {
        this.defaultLanguage = 'en'
      }
    }
    console.log("language is: ", sLanguage);

    if (this.typeParam === 'nachtraeglich') {
      if (this.defaultLanguage === 'de') {
        this.appText.de.disclaimerBtnText = 'Lösung finden'
        this.appText.de.btnStartNewText = 'Neue Vereinbarung konfigurieren'
        this.appText.de.mailToIntro = 'habe ich den Konfliktnavigator www.ihk.de/konfliktnavigator\r\ngenutzt, um einen Vorschlag für das passende Verfahren zur Lösung bei folgendem Konflikt zu erhalten:\r\n\r\n\r\nAuf Grund der von mir gemachten Angaben (siehe unten) schlägt der Konfliktnavigator vor,\r\nfolgende Vereinbarung zur Lösung des Konfliktes zu treffen:\r\n\r\n#CLAUSE#\r\n\r\nDen Vorschlag können Sie im Konfliktnavigator jederzeit nachvollziehen.\r\nAußerdem können Sie dort weitere Informationen zu den außergerichtlichen Streitbeilegungsverfahren abrufen.\r\n\r\nUnten finden Sie das Protokoll der Anfrage beim Konfliktnavigator.\r\n\r\nBitte geben Sie mir Rückmeldung, ob Sie mit diesem Vorschlag einverstanden sind.\r\n\r\nMit freundlichen Grüßen\r\n\r\nDiese E-Mail dient zur Weitergabe des Vorschlags des Konfliktnavigators an Beteiligte des Streitfalls und\r\nder Dokumentation der Anfrage. Der Vorschlag ist unverbindlich und kann eine Rechtsberatung im Einzelfall nicht ersetzen.\r\n\r\n'
      } else {
        this.appText.en.disclaimerBtnText = 'Find solution'
        this.appText.en.btnStartNewText = 'Configure new agreement'
        this.appText.en.mailToIntro = 'I used the conflict navigator www.ihk.de/conflictnavigator\r\nto get a suggestion for the appropriate procedure for solving the following conflict:\r\n\r\n\r\nBased on the information I provided ( see below), the conflict navigator proposes\r\nto reach the following agreement to resolve the conflict:\r\n\r\n#CLAUSE#\r\n\r\nYou can understand the suggestion in the conflict navigator at any time.\r\nYou can also find further information on the out-of-court dispute resolution procedures there.\r\n\r\nYou can find the log of the request to the conflict navigator below.\r\n\r\nPlease let me know if you agree with this proposal. \r\n\r\nBest regards\r\n\r\nThis e-mail serves to forward the conflict navigators proposal to those involved in the dispute and\r\nd to document the request. The proposal is non-binding and cannot replace legal advice in individual cases.\r\n\r\n'
      }
  } else {
      if (this.defaultLanguage === 'de') {
        this.appText.de.disclaimerBtnText = 'Klausel konfigurieren'
        this.appText.de.btnStartNewText = 'Neue Klausel konfigurieren'
        this.appText.de.mailToIntro = 'habe ich den Konfliktnavigator www.ihk.de/konfliktnavigator\r\nzur Vorsorge für einen möglichen Konfliktfall bei folgendem Vertrag genutzt:\r\n\r\n\r\nAuf Grund der von mir gemachten Angaben (siehe unten) schlägt der Konfliktnavigator vor,\r\nfolgendes außergerichtliches Konfliktlösungsverfahren im Vertrag zu vereinbaren:\r\n\r\n#CLAUSE#\r\n\r\nDie Empfehlung können Sie im Konfliktnavigator jederzeit nachvollziehen.\r\nAußerdem können Sie dort weitere Informationen zu den außergerichtlichen Streitbeilegungsverfahren abrufen.\r\n\r\nUnten finden Sie das Protokoll der Anfrage beim Konfliktnavigator.\r\n\r\nBitte geben Sie mir Rückmeldung, ob Sie mit diesem Vorschlag einverstanden sind.\r\n\r\nmit freundlichen Grüßen\r\n\r\nDiese E-Mail dient zur Weitergabe des Vorschlags des Konfliktnavigators an den Vertragspartner und der\r\nDokumentation der Anfrage. Der Vorschlag ist unverbindlich und kann eine Rechtsberatung im Einzelfall nicht ersetzen.\r\n\r\n'
      } else {
        this.appText.en.disclaimerBtnText = 'Configure clause'
        this.appText.en.btnStartNewText = 'Configure new clause'
        this.appText.en.mailToIntro = 'I used the conflict navigator www.ihk.de/conflictnavigator\r\nto prepare for a possible conflict in the following contract:\r\n\r\n\r\nBased on the information I provided (see below), the conflict navigator fails propose\r\nto agree on the following out-of-court conflict resolution procedure in the contract:\r\n\r\n#CLAUSE#\r\n\r\nYou can follow the recommendation at any time in the conflict navigator.\r\nYou can also find further information there access the out-of-court dispute resolution procedure.\r\n\r\nYou can find the log of the request to the conflict navigator below.\r\n\r\nPlease let me know if you agree with this proposal.\r\n\r\n Best regards\r\n\r\nThis e-mail serves to pass on the conflict navigators suggestion to the contractual partner and the\r\ndocumentation of the request. The proposal is non-binding and cannot replace legal advice in individual cases.\r\n\r\n'
      }
    }

    let tempLoginParam = this.gup('login')
    if (tempLoginParam === 'true') {
      this.loginParam = true
    }

    this.fetchQuestions()
  },
  updated: function () {
    this.addClass = this.currentQuestion.questionType

    // new question // set defaults
    if (this.newQuestion === true) {
      this.toggleShowHelp = false
      this.viewModeMessage = false

      if (this.currentQuestion.questionType === 'infoQuestion') {
        this.nextTarget = this.currentQuestion.justGoTo
      } else {
        this.nextTarget = ''
      }

      if (this.currentQuestion.questionType === 'resultQuestion') {
        if (this.currentQuestion.clause !== '' && this.currentQuestion.clause !== undefined) {
          this.clauseTextPlain = this.currentQuestion.clause
          this.clauseText = this.currentQuestion.clause
          while (this.clauseTextPlain.indexOf('#XXX#') !== -1) {
            this.clauseTextPlain = this.clauseTextPlain.replace('#XXX#', this.ihkReviewerOne)
            this.clauseText = this.clauseText.replace('#XXX#', '<strong>' + this.ihkReviewerOne + '</strong>')
          }

          while (this.clauseTextPlain.indexOf('#XXX2#') !== -1) {
            this.clauseTextPlain = this.clauseTextPlain.replace('#XXX2#', this.ihkReviewerTwo)
          }
          while (this.clauseText.indexOf('#XXX2#') !== -1) {
            this.clauseText = this.clauseText.replace('#XXX2#', '<strong>' + this.ihkReviewerTwo + '</strong>')
          }

          this.clauseTextPlain = this.clauseTextPlain.replace(/<a\b[^>]*>/i, '').replace(/<\/a>/i, '')
          this.clauseTextPlain = this.clauseTextPlain.replace(/(<([^>]+)>)/ig, '')

          let tempStr = ''
          tempStr += this.mailToSalutation
          let currentDate = this.getCurrentDate()
          this.mailToDate = this.mailToDate.replace('#DATE#', currentDate)
          tempStr += this.mailToDate
          tempStr += this.mailToIntro
          tempStr = tempStr.replace('#CLAUSE#', this.clauseTextPlain)

          tempStr += '\r\n' + this.localized('summaryHeadline') + '\r\n'
          for (let i = 0; i < this.history.length; i++) {
            if (this.history[i].questionType === 'yesNoQuestion' || this.history[i].questionType === 'multiQuestion' || this.history[i].questionType === 'selectQuestion') {
              if (this.history[i].headline !== undefined && this.history[i].headline !== '') {
                tempStr += this.history[i].headline + '\r\n'
              }
              if (this.history[i].description !== undefined && this.history[i].description !== '') {
                tempStr += this.history[i].description + '\r\n'
              }
              if (this.history[i].selection !== undefined && this.history[i].selection !== '') {
                tempStr += this.history[i].selection + '\r\n'
              }
              tempStr += '\r\n'
            }
          }

          this.clauseTextClipboard = tempStr.replace(/(<([^>]+)>)/ig, '')

          this.mailToLink = this.createMailToLink(
            this.clauseTextPlain)
        }
        this.resultQuestion = true
        if (this.currentQuestion.justGoTo !== '') {
          this.nextTarget = this.currentQuestion.justGoTo
        }
      }

      if (this.currentQuestion.questionType === 'yesNoQuestion') {
        this.resultQuestion = false
        let historyEntry = this.history[this.historyCurrentPos]
        if (historyEntry !== undefined) {
          if (this.currentQuestion.questionType === 'yesNoQuestion') {
            if (historyEntry.value === 'yes') {
              this.yesToggle = true
              this.noToggle = false
              this.currentQuestion.yesSelected = true
              this.currentQuestion.noSelected = false
              this.nextTarget = this.$refs.qYes.attributes['data-target'].nodeValue
            } else if (historyEntry.value === 'no') {
              this.noToggle = true
              this.yesToggle = false
              this.currentQuestion.noSelected = true
              this.currentQuestion.yesSelected = false
              this.nextTarget = this.$refs.qNo.attributes['data-target'].nodeValue
            } else {
              this.yesToggle = false
              this.noToggle = false
            }
          }
        }
      }

      if (this.currentQuestion.questionType === 'selectQuestion') {
        let historyEntry = this.history[this.historyCurrentPos]
        if (historyEntry.value !== '-1') {
          this.selectBox = historyEntry.value
          this.nextTarget = this.currentQuestion.justGoTo
        } else {
          this.selectBox = -1
        }
      }

      if (this.currentQuestion.questionType === 'multiQuestion') {
        let historyEntry = this.history[this.historyCurrentPos]
        if (historyEntry.value !== '-1') {
          let value = historyEntry.value
          value++
          let id = 'question' + value
          let elem = this.$refs[id][0]
          elem.classList.add('selected')
          this.nextTarget = this.currentQuestion.list[id].goTo
        }
      }

      if (this.currentQuestion.questionType === 'resultQuestion') {
        this.viewMode = true
      }

      this.initProgressBar()

      this.newQuestion = false
    } else {
      // console.log('update not new');
    }

    // go back to last question
    if (this.lastQuestion === true) {
      this.viewModeMessage = false

      this.yesToggle = this.currentQuestion.yesSelected
      if (this.currentQuestion.yesSelected === true) {
        this.nextTarget = this.currentQuestion.yesGoTo
      }
      this.noToggle = this.currentQuestion.noSelected
      if (this.currentQuestion.noSelected === true) {
        this.nextTarget = this.currentQuestion.noGoTo
      }

      if (this.currentQuestion.questionType === 'infoQuestion') {
        this.nextTarget = this.currentQuestion.justGoTo
      }

      if (this.currentQuestion.questionType === 'selectQuestion') {
        let historyEntry = this.history[this.historyCurrentPos]
        if (historyEntry.value !== '-1') {
          this.selectBox = historyEntry.value
          this.nextTarget = this.currentQuestion.justGoTo
        }
      }

      if (this.currentQuestion.questionType === 'multiQuestion') {
        let historyEntry = this.history[this.historyCurrentPos]
        if (historyEntry.value !== '-1') {
          let value = historyEntry.value
          value++
          let id = 'question' + value
          let elem = this.$refs[id][0]
          elem.classList.add('selected')
          this.nextTarget = this.currentQuestion.list[id].goTo
        }
      }

      this.initProgressBar()

      this.lastQuestion = false
      this.resultQuestion = false
    }
    if (this.nextTarget !== '') {
      this.errorSelect = false
    }
  },
  data: function () {
    return {
      defaultLanguage: "de",
      appText: {
        de: {
          disclaimerText: '<p>Dieser Service richtet sich an Unternehmen und wurde mit großer Sorgfalt und nach bestem Wissen erstellt. Er dient Ihrer Orientierung und kann eine individuelle Beratung nicht ersetzen. Eine Haftung kann nicht übernommen werden.</p><p><strong>Achtung:</strong> Bei gesetzlichen oder vertraglichen Ausschlussfristen oder der Erforderlichkeit von einstweiligem Rechtsschutz sollte unbedingt Rechtsrat eingeholt werden.</p><p>Aus Gründen der besseren Lesbarkeit wird durchgehend das generische Maskulinum verwendet. Sämtliche Personenbezeichnungen gelten gleichwohl für alle Geschlechter.</p>',
          disclaimerLinkText: 'Ich habe die Hinweise verstanden und akzeptiere den Haftungsausschluss.',
          disclaimerBtnText: '',
          btnStartNewText: '',
          mailToIntro: '',
          btnPrevious: 'Zurück',
          btnNext: 'Weiter',
          btnShare: 'Teilen',
          btnCopyToClipboard: 'Kopieren',
          summaryHeadline: 'Zusammenfassung Ihrer Auswahl',
          backToQuestion: 'Zurück zur Frage',
          stepText: 'Schritt',
          stepsText: ' Schritte',
          stillText: 'noch',
          maxText: 'maximal',
          ofText: 'von',
          errorMsgNoSelection: 'Bitte wählen Sie eine Antwort aus.',
          noSelection: 'Keine Auswahl',
          helpHeadline: 'Hinweis:',
          newSelection: 'Sie haben bereits ein Ergebnis. Mit dem Ändern Ihrer Auswahl geht das Ergebnis verloren.',
          newSelectionCancel: 'Abbrechen',
          newSelectionOk: 'Einverstanden',
          printResult: 'Ergebnis drucken/',
          saveAsPdf: 'pdf speichern',
          backToTheResult: 'Zurück zum Ergebnis',
          questionByStartOfNewTest: 'Wollen Sie wirklich eine neue Auswahl beginnen? Das bestehende Ergebnis geht verloren.',
          yes: 'Ja. ',
          legalNotice: 'Impressum',
          dataProtection: "Datenschutz",
          changeDataProtectionSettings1: 'Datenschutz-',
          changeDataProtectionSettings2: 'Einstellungen',
          changeDataProtectionSettings3: 'ändern'

        },
        en: {
          disclaimerText: '<p>This service is aimed at companies and was created with great care and to the best of our knowledge. It serves as a guide and cannot replace individual advice. Liability cannot be assumed.</p><p><strong>Caution:</strong> Legal advice should be obtained in the event of statutory or contractual deadlines or the need for interim legal protection.</p><p>For reasons of better readability, the generic masculine is used throughout. All personal designations apply to all genders.</p>',
          disclaimerLinkText: 'I have understood the information and accept the disclaimer.',
          disclaimerBtnText: '',
          btnStartNewText: '',
          mailToIntro: '',
          btnPrevious: 'Back',
          btnNext: 'Next',
          btnShare: 'Share',
          btnCopyToClipboard: 'Copy to clipboard',
          summaryHeadline: 'Summary of your selection',
          backToQuestion: 'Back to the question',
          stepText: 'Step',
          stepsText: ' steps',
          stillText: 'still',
          maxText: 'maximum',
          ofText: 'of',
          errorMsgNoSelection: 'Please choose an answer.',
          noSelection: 'No selection',
          helpHeadline: 'Notice',
          newSelection: 'You already have a result. If you change your selection, the result will be lost.',
          newSelectionCancel: 'Cancel',
          newSelectionOk: 'Agreed',
          printResult: 'Print result/',
          saveAsPdf: 'save pdf',
          backToTheResult: 'Back to the result',
          questionByStartOfNewTest: 'Are you sure you want to start a new selection? The existing result will be lost.',
          yes: 'Yes. ',
          legalNotice: 'Legal Notice',
          dataProtection: "Data Protection",
          changeDataProtectionSettings1: 'Change',
          changeDataProtectionSettings2: 'Data Protection',
          changeDataProtectionSettings3: 'Settings'
        }
      },
      //privacyLink: this.$refs.cookieBanner.privacyLink,
      localDataNachtraeglich: "1724753946-scandio.json",
      localDataPraeventiv: "1724072423-scandio.json",
      appInsights: null,
      history: [{ 'id': 'q001', 'questionType': 'multiQuestion', 'headline': '', 'description': '', 'selection': '', 'value': '-1' }],
      historyCurrentPos: 0,
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
      yesToggle: false,
      noToggle: false,
      nextTarget: '',
      lastTarget: '',
      newQuestion: false,
      lastQuestion: false,
      resultQuestion: false,
      errorSelect: false,
      errorMsg: 'Bitte wählen Sie eine Antwort aus.',
      ihkReviewerOne: '',
      ihkReviewerTwo: '',
      clauseText: '',
      clauseTextPlain: '',
      clauseTextClipboard: '',
      mailToLink: '',
      mailToSalutation: 'Sehr geehrte Damen und Herren, ',
      mailToDate: 'am #DATE# Uhr ',
      mailToIntro: 'habe ich den Konfliktnavigator www.ihk.de/konfliktnavigator\r\nzur Vorsorge für einen möglichen Konfliktfall bei folgendem Vertrag genutzt:\r\n\r\n\r\nAuf Grund der von mir gemachten Angaben (siehe unten) schlägt der Konfliktnavigator vor,\r\nfolgendes außergerichtliches Konfliktlösungsverfahren im Vertrag zu vereinbaren:\r\n\r\n#CLAUSE#\r\n\r\nDen Vorschlag können Sie im Konfliktnavigator jederzeit nachvollziehen.\r\nAußerdem können Sie dort weitere Informationen zu den außergerichtlichen Streitbeilegungsverfahren abrufen.\r\n\r\nUnten finden Sie das Protokoll der Anfrage beim Konfliktnavigator.\r\n\r\nBitte geben Sie mir Rückmeldung, ob Sie mit diesem Vorschlag einverstanden sind.\r\n\r\nMit freundlichen Grüßen\r\n\r\n',
      toggleShowHelp: false,
      addClass: 'init',
      questionID: 'q001',
      minMax: [0, 0],
      links: ['yesGoTo', 'noGoTo', 'justGoTo'],
      sorted: null,
      progressPercent: 0,
      progressOutput: '',
      selectBox: -1,
      disclaimerText: '<p>Dieser Service richtet sich an Unternehmen und wurde mit großer Sorgfalt und nach bestem Wissen erstellt. Er dient Ihrer Orientierung und kann eine individuelle Beratung nicht ersetzen. Eine Haftung kann nicht übernommen werden.</p><p><strong>Achtung:</strong> Bei gesetzlichen oder vertraglichen Ausschlussfristen oder der Erforderlichkeit von einstweiligem Rechtsschutz sollte unbedingt Rechtsrat eingeholt werden.</p><p>Aus Gründen der besseren Lesbarkeit wird durchgehend das generische Maskulinum verwendet. Sämtliche Personenbezeichnungen gelten gleichwohl für alle Geschlechter.</p>',
      disclaimerLinkText: 'Ich habe die Hinweise verstanden und akzeptiere den Haftungsausschluss.',
      disclaimernotAgreeYet: false,
      disclaimerAgree: false,
      disclaimerAgreeDone: false,
      loadingError: false,
       disclaimerBtnText: '',
      viewMode: false,
      viewModeMessage: false,
      btnStartNewText: '',
      btnStartNewAgree: false,
      loginParam: false,
      loginToggle: false,
      loginError: false,
      loginMsg: '',
      editModus: false,
      user: '',
      pw: '',
      t: '',
      isLoginOk: false,
      editModeActive: false,
      typeParam: 'nachtraeglich',
      selectedChamber: '',
      selectedchamberIndex: -1,
      selectedQuestionLabel: '',
      selectedQuestionDescription: '',
      isSelected: false,
      questionIdList: [],
      editQuestionIdSelect: '',
      paths: [],
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'bullet' }],
            ['link']
          ]
        }
      },
      currentQuestionBackup: {
      },
      currentQuestionChanges: {
      },
      currentQuestion: {
        id: '',
        questionType: 'yesNoQuestion',
        headline: '',
        subHeadline: '',
        description: '',
        help: '',
        yesGoTo: '',
        yesLabel: '',
        yesSelected: false,
        noGoTo: '',
        noLabel: '',
        noSelected: false,
        justGoTo: ''
      },
      questions: {}
    }
  }
}
</script>

<style>

</style>
